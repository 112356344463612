.swiper-main-banners {
  padding-bottom: 30px !important;
  .swiper {
    &-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        transition: background 0.2s ease;
        &-active {
          width: 12px;
          height: 12px;
          background: var(--primary-color);
        }
        &:active {
          background: unset;
        }
      }
    }
  }
}
